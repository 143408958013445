@import "./config.scss";
@import "./default.scss";
@import "./model.scss";

@import "../components/shared/privilege/Privilege";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

a {
  color: #000000;
}

a,
a:hover {
  text-decoration: none;
}

ul,
p {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

html {
  scroll-behavior: smooth;
  //font-size: 62.5%;
}

body {
  font-family: "Roboto-Regular";
  //font-weight: 400;
  //font: normal normal 300 16px/21px Roboto;
  //font-size: 1.6rem;
  //line-height: 1.7;
  color: $main-app-color;
  //padding: 3rem;
  box-sizing: border-box;
  overflow-x: hidden !important;
  @include custom-scrollbar;
}

// html[lang="ar"] body {
//   direction: rtl;
// }

.app {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-x: hidden !important;
}

.ant-table {
  ::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #d5d5d5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $secondary-app-color;
    //background-color: #b5b5b5;
    border-radius: 10px;

    &:hover {
      background: $secondary-app-color-light;
    }
  }
}

// .btn {
//   border-radius: 12px;
//   padding: 4px 10px;
//   cursor: pointer;
//   font-size: 13px;
//   font-weight: 500;
//   background-color: #31c085;
//   color: #fff;
// }

.btn--m {
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(#000, 0.2);
  }
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(#000, 0.2);
  }
}
