.lists--modal {
  .error-message {
    color: red;
    text-align: center;
    margin: 5px 0px;
  }
  .ant-modal-content {
    .ant-modal-header {
      background: $main-app-color;
      border-bottom: 1px solid $main-app-color;
      .ant-modal-title {
        color: #fff;
        font-weight: 600;
        font-size: 20px;
      }
    }

    .ant-modal-close {
      svg {
        color: #fff;
        font-size: larger;
      }
    }

    .ant-modal-body {
      form {
        display: flex;
        flex-flow: column;
        gap: 10px;
        margin: 0px 63px;

        @include selectAntd(
          $main-app-color,
          $main-app-color-light,
          $secondary-app-color
        );
        @include oneCheckBox;
        @include twoButtonAnt;
        .text-area {
          .ant-input {
            margin-top: 15px;
            &:focus,
            &:hover,
            &.ant-input-focused {
              box-shadow: 0 0 0 2px rgba($main-app-color, 0.2);
              border: 1px solid $main-app-color;
            }
          }
        }
        .ant-select-arrow .anticon > svg {
          color: $main-app-color;
          font-size: 16px;
        }

        .input-group {
          margin-bottom: 20px;

          .label {
            color: $color-black !important;
            font-weight: 600;
            font-size: 16px;
          }

          .ant-input-prefix {
            margin-right: 15px;
          }
          .ant-input-affix-wrapper {
            background: #eff2f5;
            border: 0.5px solid $color-text;
            border-radius: 12px;
            margin: 7px 0px 0px 0px;
            min-height: 35px;
            &:focus,
            &-focused {
              border: 1px solid $main-app-color;
              box-shadow: 0 0 0 2px rgba($main-app-color-light, 0.7);
            }
          }

          .ant-input-affix-wrapper {
            &:focus .ant-input-prefix svg,
            &-focused .ant-input-prefix svg {
              #Path_81,
              #Path_47,
              #Path_59 {
                fill: $main-app-color !important;
              }
            }
          }

          .ant-input-affix-wrapper {
            .ant-input-prefix svg {
              #Path_81,
              #Path_47,
              #Path_59 {
                fill: $color-gray;
              }
            }
          }

          .ant-input {
            background: #eff2f5;
          }

          .error {
            color: red;
            padding: 15px;
          }
        }

        .ant-select {
          width: 100%;
          margin-top: 10px;
          .ant-select-selection-item,
          .ant-select-selection-placeholder {
            display: flex;
            place-items: center;
            gap: 5px;
          }
        }

        .ant-input-number {
          //width: 100%;
          margin: 5px;
          .ant-input-number-handler-wrap {
            //border: 0.5px solid $color-text;
            //border-radius: 0 12px 12px 0;
          }

          &:hover,
          &:focus,
          &.ant-input-number-focused {
            border-color: $main-app-color;
          }
        }
      }
    }
  }
}
