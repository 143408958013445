@import "../../../scss/config";

.privilege {
  &--title {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    .privilege--btn-add {
      border-radius: 12px;
      padding: 6px 10px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 500;
      background-color: $main-app-color;
      color: #fff;
    }
  }

  .body {
    .table {
      .text-green {
        color: $main-app-color;
      }

      .text-blue {
        color: $secondary-app-color;
      }

      .status {
        color: $secondary-app-color;
        // display: flex;
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        place-items: center;
        gap: 7px;
        .dot {
          width: 7px;
          height: 7px;
          border-radius: 50%;
        }
        svg {
          width: 11px;
          height: 11px;
          path {
            fill: red;
          }
        }
      }

      .actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
        place-items: center;

        .not-active {
          svg {
            path:first-child {
              fill: #fff;
            }
          }
        }

        svg {
          width: 15px;
          height: 15px;
          cursor: pointer;
          fill: $main-app-color;

          &:nth-of-type(2),
          &:nth-of-type(1) {
            width: 15px;
            height: 15px;
          }
        }
      }

      .information-privilege {
        .table-row {
          display: grid;
          grid-template-columns: 30% 70%;
          margin-bottom: 15px;
          .title {
            font-weight: bold;
            color: #174462;
            font-size: 15px;
            display: block;
          }
        }
      }
    }
  }
}

.privilege--modal {
  &.privilege--role {
    @include selectManyAntd;
  }
  .error-message {
    color: red;
    text-align: center;
    margin: 5px 0px;
  }
  .ant-modal-content {
    .ant-modal-header {
      background: $main-app-color;
      border-bottom: 1px solid $main-app-color;
      .ant-modal-title {
        color: #fff;
        font-weight: 600;
        font-size: 20px;
      }
    }

    .ant-modal-close {
      svg {
        color: #fff;
        font-size: larger;
      }
    }

    .ant-modal-body {
      form {
        display: flex;
        flex-flow: column;
        gap: 10px;
        margin: 0px 63px;

        @include selectAntd(
          $main-app-color,
          $main-app-color-light,
          $secondary-app-color
        );
        @include oneCheckBox;
        @include twoButtonAnt;

        .ant-select-arrow .anticon > svg {
          color: $main-app-color;
          font-size: 16px;
        }

        .input-group {
          margin-bottom: 20px;

          .label {
            color: $color-black !important;
            font-weight: 600;
            font-size: 16px;
          }

          .ant-input-prefix {
            margin-right: 15px;
          }
          .ant-input-affix-wrapper {
            background: #eff2f5;
            border: 0.5px solid $color-text;
            border-radius: 12px;
            margin: 7px 0px 0px 0px;
            min-height: 35px;
            &:focus,
            &-focused {
              border: 1px solid $main-app-color;
              box-shadow: 0 0 0 2px rgba($main-app-color-light, 0.7);
            }
          }

          .ant-input-affix-wrapper {
            &:focus .ant-input-prefix svg,
            &-focused .ant-input-prefix svg {
              #Path_81,
              #Path_47,
              #Path_59 {
                fill: $main-app-color !important;
              }
            }
          }

          .ant-input-affix-wrapper {
            .ant-input-prefix svg {
              #Path_81,
              #Path_47,
              #Path_59 {
                fill: $color-gray;
              }
            }
          }

          .ant-input {
            background: #eff2f5;
          }

          .error {
            color: red;
            padding: 15px;
          }
        }

        .ant-select {
          width: 100%;
          margin-top: 10px;
          .ant-select-selection-item,
          .ant-select-selection-placeholder {
            display: flex;
            place-items: center;
            gap: 5px;
          }
        }
      }
    }
  }
}

///////////////////

.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: rgba($main-app-color-light, 0.7);
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: $main-app-color;
  border-color: $main-app-color;
}

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  background-color: rgba($main-app-color-light, 1);
}
