$main-app-color: #31c085;
$main-app-color-light: #d0f1e3;
$secondary-app-color: #174462;
$secondary-app-color-light: #487090;

$background_color: #f5f5f5;
$color-text: #194866;

$headerheight: 96px;
$sidePanelWidth: 252px;

$color-black: #000;
$color-gray: #888888;

// Robot font faces => Regular, bold, meduim , light
@font-face {
  font-family: "Roboto-Regular";
  src: url(../assets/fonts/Roboto-Regular.woff) format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url(../assets/fonts/Roboto-Bold.woff) format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url(../assets/fonts/Roboto-Medium.woff) format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Light";
  src: url(../assets/fonts/Roboto-Light.woff) format("woff");
  font-style: normal;
  font-display: swap;
}

@mixin boxContent {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  opacity: 1;
  padding: 15px;
}

@mixin dount-bar($text-color, $text-bk-color, $color-bar) {
  &.ant-progress-circle .ant-progress-text {
    background-color: $text-bk-color;
    color: $text-color;

    height: 75px;
    width: 75px;
    border-radius: 50px;

    display: grid;
    align-items: center;
  }
  .ant-progress-circle-trail {
    stroke: $main-app-color-light;
    stroke-width: 11px;
  }

  .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: $color-bar !important;
    stroke-width: 11px;
  }
}

@mixin progress-bar($heigth-bar, $color-bar) {
  .ant-progress-success-bg,
  .ant-progress-bg {
    background-color: $color-bar;
    height: $heigth-bar !important;
  }
  .ant-progress-inner {
    width: 60%;
    background-color: $main-app-color-light;
  }
  .ant-progress-text {
    text-align: left;
    font-size: 40px;
    font-weight: bold;
    color: $main-app-color;
  }
  .ant-progress-show-info .ant-progress-outer {
    margin-right: calc(-2em - 30%);
  }
}

// scroll
@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #d5d5d5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $main-app-color;
    //background-color: #b5b5b5;
    border-radius: 10px;

    &:hover {
      background: $main-app-color-light;
    }
  }
}

@mixin selectAntd($colorBoreder, $colorShadow, $colorText) {
  .ant-select {
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      border-color: $colorBoreder;
      box-shadow: 0 0 0 2px $colorShadow;
    }

    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid $colorBoreder;
      border-radius: 10px;
    }

    .ant-select-selection-item {
      color: $colorText;
    }
  }
  .ant-select-dropdown {
    border-radius: 10px;
    border: 1px solid $colorBoreder;
  }
}

@mixin selectManyAntd {
  .ant-select {
    .ant-select-selector {
      background-color: #eff2f5;
      .ant-select-selection-item {
        background-color: #fff;
        border-radius: 25px;
        color: $secondary-app-color;
        border-color: $secondary-app-color;
        place-items: center;
        height: 27px;

        padding: 0px 10px;
        //width: 107px;
        justify-content: space-between;
      }

      .anticon-close {
        vertical-align: 0;
        background-color: $main-app-color;
        color: #fff;
        border-radius: 25px;
        display: flex;
        padding: 4px;
      }
    }

    .ant-select-arrow {
      color: $secondary-app-color;
    }
  }
}

@mixin calenderAntd {
  .ant-picker {
    border: 1px solid $secondary-app-color;
    border-radius: 10px;
    &:hover,
    &.ant-picker-focused {
      box-shadow: 0 0 0 2px #48709036;
    }

    .ant-picker-input > input {
      color: $main-app-color;
    }
  }
}

@mixin titleAndText {
  .title-page {
    font-size: 20px;
    font-weight: bold;
    color: $main-app-color;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
  }

  .text {
    color: $secondary-app-color;
    font-size: 15px;
  }
}

@mixin inputFieldForm {
  .input-group {
    margin-bottom: 20px;

    .label {
      color: #707070;
      padding-left: 5px;
      font-weight: 700;
    }
    .ant-input,
    .ant-input-number {
      background: #eff2f5;
      border: 0.5px solid $color-text;
      border-radius: 12px;
      margin: 7px 0px 0px 0px;
      min-height: 35px;
      &:focus,
      .ant-input-number:focus {
        border: 1px solid $main-app-color;
      }
    }

    .ant-input-number-focused {
      box-shadow: 0 0 0 2px rgba($main-app-color, 0.2);
      border: 1px solid $main-app-color;
    }
    .error {
      color: red;
      padding: 15px;
    }
  }

  @include selectAntd(
    $main-app-color,
    $main-app-color-light,
    $secondary-app-color
  );

  .ant-select {
    width: 100%;

    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: #eff2f5;
      border: 0.5px solid $color-text;
      border-radius: 12px;
      margin: 7px 0px 20px 0px;
      min-height: 35px;
    }
    .ant-select-arrow {
      top: 40%;
      color: $color-text;
    }
  }

  .line {
    background-color: $color-text;
    height: 1px;
    margin: 0px 120px 20px 120px;
  }

  @include twoButtonAnt;
}

@mixin oneCheckBox {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #ffffff;
      border: 1.8px solid $color-text;
      &::after {
        border: 2px solid $main-app-color;
        border-top: 0;
        border-left: 0;
      }
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $main-app-color;
  }
}

@mixin radioGroup {
  .ant-radio-group {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
  }
  .ant-radio {
    &.ant-radio-checked {
      .ant-radio-inner {
        border-color: $color-text;
        &::after {
          background-color: rgba($color-text, 0.5);
        }
      }
    }
  }
}

@mixin searchAnt {
  .ant-input {
    border-radius: 24px 0px 0px 24px;
    border-color: $secondary-app-color;
    &:hover,
    &:focus {
      color: $secondary-app-color-light;
      background: #fff;
      border-color: $secondary-app-color-light;
    }

    &:focus,
    &-focused {
      box-shadow: 0 0 1px 1px rgba($secondary-app-color, 0.4);
    }
  }
  .ant-input-group-addon {
    border-radius: 24px;
  }

  .ant-btn {
    border-radius: 0px 24px 24px 0px !important;
    border-left: 0px;
    border-color: $secondary-app-color;

    &:hover,
    &:focus {
      color: $secondary-app-color-light;
      background: #fff;
      border-color: $secondary-app-color-light;
    }
  }

  .ant-btn,
  .ant-input {
    height: 33px;
  }
}

@mixin twoButtonAnt {
  .btn {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    flex-flow: wrap;
    .ant-btn-primary {
      background: $main-app-color;
      border-color: $main-app-color;
      width: 87px;
      height: 39px;
    }

    .btn-cancel {
      background: #eff2f5;
      color: $color-text;
      border-color: $main-app-color;
      width: 87px;
      height: 39px;
    }
  }
}

.calender-no-time {
  .ant-picker-datetime-panel .ant-picker-time-panel {
    display: none;
  }
}

// classes
.flex {
  display: flex;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.items-center {
  display: flex;
  align-items: center;
}
