@import "../../scss/config";

.details-property-unauth {
  padding: 1rem 2rem;

  @media screen and (max-width: 767px) {
    padding: 10px 1rem;
  }

  .details-container {
    max-width: 1400px;
    margin: 0 auto;
  }

  @include titleAndText;
  .Page-not-found-property {
    display: flex;
    place-items: center;
    height: 80vh;
    place-content: center;
    font-size: 30px;
    text-align: center;
    flex-flow: column;
  }

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    h3 {
      color: $main-app-color;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  .body {
    .title-section {
      border: 1px solid $main-app-color;
      color: $main-app-color;
      border-radius: 12px;
      padding: 5px 10px;
      font-size: 20px;
      font-weight: bold;
      background-color: #fff;
      width: max-content;
      margin: 25px 0px;
    }

    .grid-item {
      display: grid;
      grid-template-columns: repeat(2, 47%);
      gap: 6%;
      font-size: 19px;

      @media screen and (max-width: 600px) {
        border: 1px solid rgba($color: $main-app-color, $alpha: 0.4);
        padding: 5px;
      }

      .title-info {
        color: $color-text;
        font-weight: bold;

        @media screen and (max-width: 600px) {
          white-space: nowrap;
          font-size: 16px;
        }
      }
      .data-info {
        color: $main-app-color;
        align-self: center;
        //white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;

        svg:nth-of-type(1) {
          width: 13px;
          height: 13px;
        }
        svg:nth-of-type(2) {
          width: 17px;
          height: 17px;
        }
      }
    }

    .section-action {
      display: grid;
      grid-template-columns: 60% 44%;
      gap: 40px;
      // width: 50%;
      // margin: 0 auto;
      @media (max-width: 900px) {
        grid-template-columns: 100%;
        // width: 100%;
        gap: 0px;
      }
      .no-images {
        svg {
          width: 100%;
          // height: 35%;
          max-height: 400px;

          @media screen and (max-width: 767px) {
            max-height: 220px;
          }
        }
        p {
          text-align: center;
          margin: 20px 0px;
          color: black;
          font-size: 20px;
          text-decoration: underline;
          text-transform: uppercase;
        }
      }
      .carousel {
        // height: 400px;
        padding-bottom: 30px;
        position: relative;

        .react-multi-carousel-list {
          //position: initial;
          .react-multiple-carousel__arrow {
            background-color: rgba(0, 0, 0, 0);
            top: 30%;
            z-index: 10;
            &::before {
              color: $secondary-app-color;
              font-weight: bolder;
            }
          }
          .scrImage {
            //display: block;
            height: 74%;
            //margin: auto;
            width: 100%;
          }
          .react-multi-carousel-dot-list {
            height: 25%;
            margin-top: 10px;

            //flex-flow: wrap;
            img {
              margin-left: 5px;
              margin-bottom: 5px;
              //width: 50px;
              min-width: 20%;
              height: 100%;
              cursor: pointer;
            }
          }
        }
      }
    }

    .section-details-property {
      p {
        color: $color-text;
        white-space: pre-wrap;
        font-size: 16px;
        line-height: 1.8rem;
        margin-left: 5px;
      }
    }

    .section-listing {
      .data-section {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;

        @media (max-width: 567px) {
          grid-template-columns: 100%;
          gap: 7px;
        }

        .grid-item {
          font-size: 17px;

          .dot-title {
            display: grid;
            grid-template-columns: 19% 79%;
            place-content: center;
            .dot {
              height: 5px;
              width: 5px;
              background-color: $color-text;
              border-radius: 50%;
              align-self: center;

              @media screen and (max-width: 767px) {
                display: none;
              }
            }
          }
        }
      }

      .phone-icons {
        display: flex;
        justify-content: center;
        a {
          width: 24px;
          height: 24px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: $main-app-color;
          margin: 4px;

          &:last-of-type {
            background-color: #dd5656;
          }
        }
      }
    }

    .section-map {
      .map {
        border: 1px solid black;
        border-radius: 15px;
        width: 100%;
        height: 350px;
        iframe {
          width: 100%;
          height: 350px;
        }
      }
    }
  }
}

.Page-not-found-property {
  display: flex;
  place-items: center;
  height: 80vh;
  place-content: center;
  // font-size: 30px;
  text-align: center;
  flex-flow: column;

  p {
    font-size: 20px;
    margin-top: 22px;
    color: "#333" !important;
  }
  .btn {
    font-size: 18px;
    padding: 6px 18px;
    background-color: "#333";
  }
}
