@import "../../scss/config";

.page-wrap {
  display: grid;
  // grid-template-columns: 250px calc(100% - 250px);
  grid-template-columns: 75px calc(100% - 75px);
  background: $background_color;
  position: relative;

  @media (max-width: 1060px) {
    grid-template-columns: 100% !important;
  }

  .page-content-wrap {
    padding: 20px;
    min-height: calc(100vh - 96px);
  }
}

html[lang="ar"] .page-wrap {
  direction: rtl;
}
