.modal-show {
  top: 33% !important;
  .ant-modal-content {
    .ant-modal-header {
      background: $main-app-color;
      border-bottom: 1px solid $main-app-color;
      .ant-modal-title {
        color: #fff;
        text-align: center;
        font-size: 20px;
      }
    }
    .text {
      font-size: 17px;
      color: $color-text;
      margin-bottom: 15px;
    }
  }
}

.image-dispaly-change {
  display: flex;
  flex-flow: column;
  place-items: center;
  gap: 12px;

  #input-image {
    display: none;
  }
  img,
  svg {
    width: 100px;
    height: 100px;
    border: 0.1px solid;
    border-radius: 50%;
  }

  .btn-image {
    display: flex;
    gap: 31px;
    color: $secondary-app-color;
    .change,
    .delete {
      cursor: pointer;
    }
    //font-weight: bold;
  }
}
