@import "../../scss/config";

.multi-prop {
  padding: 2rem;

  @media screen and (max-width: 767px) {
    padding: 0.5rem 1rem;
  }

  .container {
    max-width: 80%;
    margin: 0 auto;

    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }

  .title {
    h3 {
      font-size: 25px;
      color: $main-app-color;
      font-weight: bold;
      margin-bottom: 1.5rem;

      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__item {
      border: 1px solid $main-app-color;
      padding: 1rem;
      border-radius: 8px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin: 10px 0;

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }

      &__images {
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        img {
          width: 300px;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }

        .count-imgs {
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 5px 9px;
          border-radius: 8px;
          display: flex;
          background-color: rgba($color: #000, $alpha: 0.4);

          span {
            font-size: 13px;
            color: #fff;
            margin-right: 5px;
          }
        }
      }

      &__details {
        margin-right: 20px;
        color: #616161;
        direction: rtl;
        width: 100%;

        @media screen and (max-width: 767px) {
          margin-right: 0;
          margin-top: 15px;
          text-align: center;
        }

        .price {
          display: flex;
          margin-bottom: 6px;
          font-size: 20px;
          line-height: 22px;
          color: #000;
          font-weight: 900;
          justify-content: flex-start;
          align-items: center;
          span {
            margin-left: 10px;
          }

          h2 {
            margin: 0;
          }

          @media screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 20px;
            justify-content: center;
          }
        }

        .title {
          font-size: 16px;
          margin: 10px 0 6px;
        }

        .location {
          display: flex;
          align-items: center;
          font-size: 16px;
          margin: 6px 0;
          svg {
            font-size: 15px;
            margin-left: 5px;
          }

          @media screen and (max-width: 767px) {
            justify-content: center;
          }
        }

        .property-details {
          display: flex;

          .space {
            span {
              margin-right: 8px;
            }
          }

          .rooms {
            margin-right: 10px;
            span {
              margin-right: 8px;
            }
          }

          .bathrooms {
            margin-right: 10px;
            span {
              margin-right: 8px;
            }
          }

          @media screen and (max-width: 767px) {
            justify-content: center;
          }
        }

        .sales {
          display: flex;
          justify-content: space-between;
          align-items: center;

          border-top: 1px solid #e0e0e0;

          margin: 10px 0 0;
          padding: 10px 0 0;

          .name {
            font-size: 20px;
          }

          .phone-icons {
            display: flex;
            justify-content: center;
            a {
              width: 35px;
              height: 35px;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              background-color: $main-app-color;
              margin: 10px;
              font-size: 18px;

              &:last-of-type {
                background-color: #dd5656;
              }
            }
          }
        }
      }
    }
  }
}
